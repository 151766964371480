import Image from "next/image";
import TreaterLogo from "../../public/treater.png";

export default function Logo({
  className = "",
  width = "w-40",
}: {
  className?: string;
  width?: string;
  height?: string;
}) {
  return <Image className={`${className} ${width}`} src={TreaterLogo} alt="Treater" />;
}
