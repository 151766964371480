import { PAGES } from "@/utils/pages";
import ExtLink from "../ExtLink";

export default function Footer() {
  const legalLinks = [
    { name: "Terms", href: PAGES.LEGAL.TERMS },
    { name: "Privacy", href: PAGES.LEGAL.PRIVACY },
  ];

  return (
    <footer className="flex flex-row justify-between w-full px-8 py-4 text-sm">
      <div>Treater, Inc.</div>
      <div className="flex flex-row justify-end gap-x-4">
        {legalLinks.map((item) => (
          <ExtLink key={item.name} href={item.href} noUnderline>
            {item.name}
          </ExtLink>
        ))}
      </div>
    </footer>
  );
}
