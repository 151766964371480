export function assertEnv(key: string): string {
  const value = process.env[key];
  if (value === undefined) {
    if (process.env.NEXT_BUILD_SET === "1") {
      return "FAKE_VALUE_FOR_NEXT_BUILD";
    }
    throw new Error(
      `Environment variable ${key} is not defined in ${JSON.stringify(process.env, undefined, 2)}`,
    );
  }
  return value;
}
