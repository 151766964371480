import { useRouter } from "next/router";
import Link from "next/link";
import Logo from "../Logo";
import clsx from "clsx";
import { useSession } from "next-auth/react";
import { PAGES } from "@/utils/pages";
import Button from "../Button";
import { UserOrganizations, useUserOrganizations } from "@/contexts/global/OrganizationContext";
import { OrganizationBrands, useOrganizationBrands } from "@/contexts/global/BrandContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { PersonIcon } from "@radix-ui/react-icons";

export default function Navigation() {
  return (
    <header className={clsx("bg-white")}>
      <nav className="flex items-center justify-between py-4 px-8">
        <div className="flex">
          <Link href="/" className="">
            <Logo width="w-12" />
          </Link>
        </div>
        <div className="flex">
          <AppLinks />
        </div>
        <div className="flex">
          <SessionLinks />
        </div>
      </nav>
    </header>
  );
}

type Link = { title: string; url: string };

function AppLinks() {
  const userOrganizations = useUserOrganizations();
  const organizationBrands = useOrganizationBrands();

  const router = useRouter();
  // TODO: the next.js docs say this shouldn't be used until isReady is set: https://nextjs.org/docs/pages/api-reference/functions/use-router#router-object.
  // Is this a problem for us?
  const path = router.asPath;

  if (!userOrganizations || !organizationBrands) return <></>;

  const orgSlug = userOrganizations.active.organization.slug;
  const brandName = organizationBrands.active.name;

  const pages: Link[] = [
    { title: "Opportunities", url: PAGES.APP.OPPORTUNITIES.INDEX(orgSlug, brandName) },
    { title: "Promotions", url: PAGES.APP.PROMOTIONS.INDEX(orgSlug, brandName) },
    { title: "Stores", url: PAGES.APP.STORES.INDEX(orgSlug, brandName) },
    { title: "Settings", url: PAGES.APP.BRAND_SETTINGS(orgSlug, brandName) },
  ];

  return (
    <div className="flex flex-row gap-x-8 text-lg font-medium">
      {pages.map(({ title, url }, i) => (
        <Link
          key={i}
          className={clsx(
            "cursor-pointer hover:text-blue-link",
            new URL(path, "http://example.com").pathname.startsWith(
              new URL(url, "http://example.com").pathname,
            ) && "text-main-800 border-main-700 border-b-2",
          )}
          href={url}
        >
          {title}
        </Link>
      ))}
    </div>
  );
}

function SessionLinks() {
  const session = useSession();

  if (!session.data) return <AnonymousSessionLinks />;

  return <ActiveSessionLinks />;
}

function AnonymousSessionLinks() {
  return (
    <div className="flex flex-row space-x-4">
      <Button href={PAGES.AUTH.SIGN_IN}>Sign in</Button>
    </div>
  );
}

function ActiveSessionLinks() {
  const userOrganizations = useUserOrganizations();
  const organizationBrands = useOrganizationBrands();

  return (
    <div className="flex flex-row space-x-4">
      {userOrganizations && organizationBrands && (
        <OrganizationLinks
          userOrganizations={userOrganizations}
          organizationBrands={organizationBrands}
        />
      )}
      <ProfileLinks />
    </div>
  );
}

function OrganizationLinks({
  userOrganizations,
  organizationBrands,
}: {
  userOrganizations: UserOrganizations;
  organizationBrands: OrganizationBrands;
}) {
  const hasMultipleOrganizations = userOrganizations.available.length > 1;
  const hasMultipleBrands = organizationBrands.available > 1;

  const showDropdown = hasMultipleOrganizations || hasMultipleBrands;

  const contents = [];
  if (userOrganizations.available.length > 1) {
    contents.push(
      <Link className="cursor-pointer hover:text-blue-link" href={PAGES.ORGANIZATIONS}>
        Switch organization
      </Link>,
    );
  }
  if (organizationBrands.available > 1) {
    contents.push(
      <Link
        className="cursor-pointer hover:text-blue-link"
        href={PAGES.APP.INDEX(userOrganizations.active.organization.slug)}
      >
        Switch brand
      </Link>,
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <picture className={clsx(showDropdown && "cursor-pointer")}>
          <img
            className="h-12 w-12 object-cover border"
            alt={organizationBrands.active.name}
            src={organizationBrands.active.primaryImageUrl}
          />
        </picture>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {contents.map((content, i) => (
          <>
            {i > 0 && <DropdownMenuSeparator key={i} />}
            <DropdownMenuItem key={i}>{content}</DropdownMenuItem>
          </>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function ProfileLinks() {
  const links: Link[] = [
    { title: "Sign out", url: PAGES.AUTH.SIGN_OUT },
    { title: "Settings", url: PAGES.SETTINGS },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <PersonIcon className="h-6 w-6"></PersonIcon>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {links.map(({ title, url }, i) => (
          <>
            {i > 0 && <DropdownMenuSeparator key={i} />}
            <DropdownMenuItem key={i}>
              <Link
                key={i}
                className="cursor-pointer hover:text-blue-link whitespace-nowrap"
                href={url}
              >
                {title}
              </Link>
            </DropdownMenuItem>
          </>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
