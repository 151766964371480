import clsx from "clsx";
import Link from "next/link";

const baseStyles =
  "leading-none gap-x-2 inline-flex items-center justify-center whitespace-nowrap transition duration-200 ease-out group";

type ButtonProps = React.ComponentProps<"button"> & {
  href?: string;
  onClick?: () => void;
  secondary?: boolean;
  destruct?: boolean;
};

export default function Button(props: ButtonProps) {
  const { className = "", disabled, type } = props;

  let accent: string;
  if (disabled) {
    accent = clsx("bg-neutral-50", "ring-1 ring-neutral-300");
  } else if (props.secondary) {
    accent = clsx("bg-neutral-50 hover:text-blue-link", "ring-1 ring-blue-link/30");
  } else if (props.destruct) {
    accent = clsx("bg-red-100 hover:text-blue-link", "ring-1 ring-red-600/30");
  } else {
    accent = clsx("bg-primary-50 hover:text-blue-link", "ring-1 ring-blue-link/30");
  }

  const classes = clsx(
    baseStyles,
    "px-2 py-1.5 font-medium shadow-sm",
    accent,
    className,
    !disabled && "cursor-pointer",
  );
  if ("href" in props && disabled) {
    // TODO: card component?
    return (
      <button type={type ?? "button"} disabled className={classes}>
        {props.children}
      </button>
    );
  } else if (props.href) {
    return (
      <Link className={classes} href={props.href}>
        {props.children}
      </Link>
    );
  } else if (props.onClick) {
    return (
      <button
        type={type ?? "button"}
        className={classes}
        onClick={props.onClick}
        disabled={disabled}
      >
        {props.children}
      </button>
    );
  } else {
    return (
      <button className={classes} type={type ?? "submit"} disabled={disabled}>
        {props.children}
      </button>
    );
  }
}
