import clsx from "clsx";
import Link from "next/link";

interface ExtLinkProps {
  href: string;
  children: string | JSX.Element;
  newTab?: boolean;
  noUnderline?: boolean;
}

export default function ExtLink({
  href,
  children,
  noUnderline,
  newTab,
}: ExtLinkProps): JSX.Element {
  return (
    <Link
      className={clsx("cursor-pointer text-blue-link", !noUnderline && "underline")}
      target={newTab ? "_blank" : undefined}
      href={href}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(href, newTab ? "_blank" : "_self");
      }}
    >
      {children}
    </Link>
  );
}
